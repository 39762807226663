/** @jsx jsx */
import { Component, Fragment } from "react"
import { graphql, Link } from "gatsby"
import { jsx } from "theme-ui"
import { Heading, Text, Box } from "@theme-ui/components"
import { FixedLayout, TheSeo } from "x"

export default class Partners extends Component {
	render() {
		const {
			data: {
				craft: { entry },
			},
			pageContext: { ...pageContext },
		} = this.props

		return (
			<Fragment>
				<TheSeo title={entry.title} />
				<FixedLayout
					image={entry.featuredImage[0].filename}
					imageTitle={`“Black and silver vintage camera” by Alexander Andrews (via unsplash.com)`}
					imageBackgroundColor="#333"
				>
					<Box>
						<Link
							to={`/the-office-guys/`}
							sx={{
								variant: "links.breadcrumb",
							}}
						>
							/ Office Guys
						</Link>
						<Heading variant="display">{entry.title}</Heading>
						<Text
							variant="bodytext"
							className="reading"
							dangerouslySetInnerHTML={{ __html: entry.body.content }}
						/>
					</Box>
				</FixedLayout>
			</Fragment>
		)
	}
}

export const partnerQuery = graphql`
	query QueryPartners($id: [Int]!) {
		craft {
			entry(id: $id) {
				... on Craft_Partners {
					title
					body {
						content
					}
					headshot {
						filename
					}
					featuredImage {
						filename
					}
				}
			}
		}
	}
`
